import React, { ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { CURRENT_USER_QUERY } from '@koncert/graphql';
import PageLoader from '../Common/PageLoader';
import { DocumentNode } from 'graphql';

export const UserContext = React.createContext({});

export const UserProvider = ({
  query,
  children,
}: {
  query?: DocumentNode;
  children: ReactNode;
}) => {
  const [loadingTooLong, setLoadingTooLong] = useState(false);

  const { client, data, loading, error, refetch } = useQuery(
    query ?? CURRENT_USER_QUERY,
    {
      fetchPolicy: 'network-only'
    }
  );
  const { logout: aut0Logout } = useAuth0();

  const logout = (returnTo: string) => {
    client.clearStore();
    aut0Logout({ returnTo });
  };

  useEffect(() => {
    let isMounted = true;
    const timer = setTimeout(() => {
      if (isMounted && loading) {
        setLoadingTooLong(true);
      }
    }, 20000);

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [loading]);

  if (loading) {
    return (
      <UserContext.Provider value={{ error, loading, logout }}>
        <PageLoader />
      </UserContext.Provider>
    );
  }

  if (loadingTooLong || error) {
    throw new Error('Timeout');
  }

  const user = !error ? data.me : null;
  return (
    <UserContext.Provider value={{ user, loading, error, logout, refetch }}>
      {!loading && children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
