import gql from 'graphql-tag';

export const CONTACT_DATA_FREQUENCIES = gql`
  query contactDataFrequencies($filter: JSON, $limit: Int = 50) {
    contactDataFrequencies(
      filter: $filter
      order: { count: DESC }
      limit: $limit
    ) {
      _id
      value
      dataPointClassName
      percentageOfTotal
      count
    }
    contactDataFrequenciesMeta(filter: $filter) {
      count
    }
  }
`;

export default CONTACT_DATA_FREQUENCIES;
