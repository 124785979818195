/**
 * @author @rkrishna-gembrill
 * @since Jun 22 2020
 * @version V11.0
 */
import React from 'react';
import { Button } from 'reactstrap';

// TODO: when converting to typescript, please extend the props type with html button and reactstrap button
const CloseButton = (props) => {
  const newProps = { ...props };
  delete newProps.btnTxt;

  return (
    <Button {...newProps} color="secondary" title={props.btnTxt}>
      <i className="fa fa-times mr-2"></i>
      {props.btnTxt}
    </Button>
  );
};

CloseButton.defaultProps = {
  btnTxt: 'Close',
};

export default CloseButton;
