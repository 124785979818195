import gql from 'graphql-tag';

export const ACCOUNTCOVERAGEAGGREGATION = gql`
  query accountCoverageAggregation(
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
    $sendersId: [ID!]!
  ) {
    accountCoverageAggregation(
      format: $format
      startDate: $startDate
      endDate: $endDate
      sendersId: $sendersId
    ) {
      id
      startDate
      endDate
      data
      totalCount
      totalAccountCount
      totalDnc
    }
  }
`;

export default ACCOUNTCOVERAGEAGGREGATION;
