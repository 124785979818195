/*
 * @author @rManimegalai
 * @version V11.0
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import moment from 'moment';
import LicenseUserContext from '../LicenseUserContext';
import useEmailAccounts from './hooks/UseEmailAccounts';

const EmailVerificationAlert = () => {
  const { user: licenseUser, loading: licenseUserLoading } = useContext(
    LicenseUserContext
  );
  const currentUserId = licenseUserLoading || !licenseUser ? 0 : licenseUser.id;
  const [verified, setVerified] = useState(false);
  const [perDayLimitCrossed, setPerDayLimitCrossed] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [disabledEndDate, setDisabledEndDate] = useState(null);
  const hoursRef = useRef(0);
  const minuteRef = useRef(0);
  const onDismiss = () => {
    setVerified(false);
    setPerDayLimitCrossed(false);
    setIsDisable(false);
  };
  const {
    error: emailAccountsError,
    loading: emailLoading,
    primaryEmail,
  } = useEmailAccounts(currentUserId);

  useEffect(() => {
    if (primaryEmail) {
      getEmailVerifiedAndGovernData(primaryEmail);
    }

    if (emailAccountsError) {
      setVerified(false);
      setPerDayLimitCrossed(false);
    }
  }, [primaryEmail, emailAccountsError]);

  const getEmailVerifiedAndGovernData = (primaryEmail) => {
    let formattedEndDate;
    if (primaryEmail) {
      setVerified(!primaryEmail?.verified);
      if (primaryEmail?.outOfOfficeEndDate !== null) {
        const startDate = primaryEmail?.outOfOfficeStartDate;
        const endDate = primaryEmail?.outOfOfficeEndDate;
        formattedEndDate = moment(
          primaryEmail?.outOfOfficeEndDate,
          'YYYY-MM-DD HH:mm Z'
        ).format('M/D/YYYY');

        const currentDateTime = new Date();
        const isToday = moment(startDate).isSame(currentDateTime);
        const range = moment(currentDateTime).isBetween(startDate, endDate);
        if (isToday || range) {
          setIsDisable(true);
        }
      } else {
        setIsDisable(false);
      }

      setDisabledEndDate(formattedEndDate);
      setPerDayLimitCrossed(
        primaryEmail.emailPerdayLimitCrossedDatetime && true
      );
      if (primaryEmail?.emailPerdayLimitCrossedDatetime !== null) {
        const dateTime = primaryEmail.emailPerdayLimitCrossedDatetime.split(
          'T'
        )[1];
        hoursRef.current = dateTime.split(':')[0];
        minuteRef.current = dateTime.split(':')[1];
      }
    } else {
      setVerified(true);
    }
  };

  return (
    <Alert
      isOpen={verified || perDayLimitCrossed || isDisable}
      color="warning"
      className="rounded-0"
    >
      {!emailLoading && (
        <>
          <div className="float-right">
            <i
              className="fas fa-times-circle pointer text-right text-dark"
              onClick={onDismiss}
            ></i>
          </div>
          {perDayLimitCrossed && (
            <div className="d-flex justify-content-center text-dark">
              {'You have exceeded the last 24-hour email sending limit. You will be eligible to send the next email in ' +
                (hoursRef.current > 0 && hoursRef.current) +
                ' hours and ' +
                (minuteRef.current > 0 && minuteRef.current) +
                ' minutes'}
            </div>
          )}
          {verified && (
            <Link
              className="d-flex justify-content-center text-dark"
              to={{
                pathname: '/settings/email/accounts',
              }}
            >
              Your email account is invalid. Please click here to update your
              information.
            </Link>
          )}
          {!verified && isDisable && disabledEndDate !== null && (
            <Link
              className="d-flex justify-content-center text-dark"
              to={{
                pathname: '/settings/email/accounts',
              }}
            >
              {`Your email account has been disabled and emails will resume after
                ${disabledEndDate}`}
            </Link>
          )}
        </>
      )}
    </Alert>
  );
};

export default EmailVerificationAlert;
