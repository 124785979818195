import gql from 'graphql-tag';

export const USER_STORY_CONTACTS_QUERY = gql`
  query storyContacts(
    $filter: JSON!
    $limit: Int = 25
    $skip: Int = 0
    $order: StoryContactQueryArgsSort = {
      priority: ASC
      personalizationScore: DESC
    }
  ) {
    storyContacts(filter: $filter, limit: $limit, skip: $skip, order: $order) {
      _id
      updatedAt
      account {
        _id
        salesforceIdsValue
        name {
          value
        }
        nameValue
        salesforceIds {
          value
        }
      }
      contact {
        _id
        salesforceIdsValue
        givenName {
          value
        }
        familyName {
          value
        }
        salesforceIds {
          value
        }
        lastApproachedAt
        lastContactedAt
        salesforceLastActivityDate {
          dateValue
        }
        position {
          email
          title
        }
      }
      status
      syncErrorMessage
      approved
      sender {
        fullName
      }
      story {
        _id
        name
        priority
      }
      senderId
      priority
      personalizationScore
      emailContent
      emailPreview
      lastViewedAt
    }
    storyContactsMeta(filter: $filter) {
      count
    }
  }
`;

export const USER_STORY_CONTACT_COUNTS = gql`
  query storyContactCounts($filter: JSON!) {
    storyContactsMeta(filter: $filter) {
      count
    }
  }
`;

export const USER_STORY_CONTACT_MESSAGES_QUERY = gql`
  query storyContact($id: ID!) {
    storyContact(where: { _id: $id }) {
      _id
      emailContent
      elementToMessageLookup
      lastViewedAt
      elements {
        _id
        type
        text
        senderId
        triggerDataPoints {
          _id
          fieldName
          recordType
          fieldValue
        }
        plotPointAsAdditional {
          _id
          name
          position
          createdAt
          message {
            _id
            name
          }
        }
        plotPointAsDefault {
          _id
          name
          position
          createdAt
          message {
            _id
            name
          }
        }
      }
    }
  }
`;

export const USER_STORIES_CONTACTS_QUERY = gql`
 query storyContactsWithFilter( $status : [String!], $senderId: ID, $name: String, $skip: Int = 0, $limit: Int = 10 ) {
    storyContactsWithFilter( status: $status, senderId: $senderId, name: $name, skip: $skip, limit: $limit )
   }
`;

export const USER_STORIES_CONTACTS_COUNT_QUERY = gql`
 query storyContactsCountWithFilter( $status : [String!], $senderId: ID, $name: String ) {
  storyContactsCountWithFilter( status: $status, senderId: $senderId, name: $name )
    }
`;