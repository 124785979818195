import React, { useState, useMemo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Sparklines, SparklinesLine } from 'react-sparklines';
import { LinearGradientFill } from '@koncert/shared-components';
import { STORYJOURNALAGGREGATION } from '@koncert/graphql';
import { useQuery } from '@apollo/client';
import { UserContext } from '@koncert/shared-components';
import * as dayjs from 'dayjs';

const StoryJournalGraph = ({
  customerId,
  title,
  color,
  bgColorClass,
  startColor,
  endColor,
  event,
  format,
  startDate,
  endDate,
  storyId,
  groupByStoryId,
  sendersId,
  sendersLoading,
  groupBySender,
  groupByAccount,
  accountId,
  darkText,
  period,
  fromStory,
}) => {
  const { user, loading: userLoading } = useContext(UserContext);

  const [fixedData, setFixedData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const dateValidation =
    !startDate || !endDate || dayjs(startDate).diff(dayjs(endDate)) > 0
      ? false
      : true;

  const { loading, data } = useQuery(STORYJOURNALAGGREGATION, {
    variables: {
      customerId: customerId || user?.companyId,
      format: format,
      event: event,
      startDate: startDate,
      endDate: endDate,
      storyId: storyId,
      groupByStoryId: groupByStoryId,
      sendersId: sendersId || [user?._id],
      groupBySender: groupBySender,
      groupByAccount: groupByAccount,
      accountId: accountId,
    },
    skip: userLoading || !dateValidation || sendersLoading,
  });

  useMemo(() => {
    if (data && data.storyJournalAggregation) {
      const dataArray = data.storyJournalAggregation.data.map((x) => x.count);
      setFixedData(dataArray.concat(Array(5).fill(1)).slice(0, 5));
      setTotalData(
        groupByAccount
          ? data.storyJournalAggregation.totalAccountCount
          : data.storyJournalAggregation.totalCount
      );
    }
  }, [data, groupByAccount]);

  return (
    <div className={'card ' + bgColorClass + ' pt-2 b'}>
      <div
        className={
          darkText === true ? 'px-2 text-dark flex-grow-2' : 'px-2 flex-grow-2'
        }
      >
        <div className="h2 mt0">
          {loading && <i className="fa fa-spinner fa-spin"></i>}
          {!loading && <span>{!dateValidation ? '0' : totalData}</span>}
        </div>
        <div>{title}</div>
      </div>

      {
        loading && '...'
        //   options={{
        //     type: 'line',
        //     width: '100%',
        //     height: '75px',
        //     lineColor: color,
        //     chartRangeMin: '0',
        //     fillColor: color,
        //     spotColor: color,
        //     minSpotColor: color,
        //     maxSpotColor: color,
        //     highlightSpotColor: color,
        //     highlightLineColor: color,
        //     resize: true,
        //   }}
        //   values="0,0,0,0,0,0"
        //   style={{ marginBottom: '-2px' }}
        // />
      }
      {!loading && (
        // <Sparkline
        //   options={{
        //     type: 'line',
        //     width: '100%',
        //     height: '75px',
        //     lineColor: color,
        //     chartRangeMin: '0',
        //     fillColor: color,
        //     spotColor: color,
        //     minSpotColor: color,
        //     maxSpotColor: color,
        //     highlightSpotColor: color,
        //     highlightLineColor: color,
        //     resize: true,
        //   }}
        //   values={fixedData}
        //   style={{ marginBottom: '-2px' }}
        // />
        <div className="p-2 sparklink-graph">
          <Sparklines data={fixedData} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: '#83CFFF',
              }}
            />
          </Sparklines>
        </div>
      )}
      {!loading && fixedData.length === 0 && (
        <div>
          <Sparklines data={[0, 0, 0, 0]} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: '#83CFFF',
              }}
            />
          </Sparklines>
        </div>
      )}
      <div className="card-footer">
        {!fromStory && (
          <p>
            <em className="fa fa-chart-bar fa-fw mr-1"></em>
            <span className="mr-1">{period}</span>
            <span className="d-inline-block">
              ({dayjs(startDate).format('MM/DD/YYYY')} -{' '}
              {dayjs(endDate).format('MM/DD/YYYY')})
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerId: state.customer._id,
  customerName: state.customer.name,
});
const connectedStoryJournalGraph = connect(mapStateToProps)(StoryJournalGraph);

export default withTranslation('translations')(connectedStoryJournalGraph);
