import gql from 'graphql-tag';

export const FIELD_MAPPINGS_FIELDS_QUERY = gql`
  query fields($filter: JSON!, $limit: Int = 500, $skip: Int = 0) {
    fields(filter: $filter, limit: $limit, skip: $skip) {
      _id
      customerId
      createdUserId
      modifiedUserId
      mandatory
      implicit
      readonly
      hidden
      sortable
      maxLength
      name
      label
      dataType
      controlType
      recordType
      mappingMandatory
      native
      crmFieldMappings {
        _id
        crmFieldName
        syncRuleFromCrm
        syncRuleToCrm
      }
      fieldDropDownValues {
        _id
        name
      }
    }
  }
`;

export const FIELD_MAPPINGS_FIELD_QUERY = gql`
  query field($id: ID!) {
    field(where: { _id: $id }) {
      _id
      customerId
      createdUserId
      modifiedUserId
      mandatory
      implicit
      readonly
      hidden
      sortable
      maxLength
      name
      label
      dataType
      controlType
      native
      crmFieldMappings {
        _id
        crmFieldName
        __typename
      }
      fieldDropDownValues {
        _id
        name
        __typename
      }
      __typename
    }
  }
`;

export const FIELD_TYPE_QUERY = gql`
  query getDataAndControlTypes {
    getDataAndControlTypes {
      dataTypes
      controlTypes {
        text
        boolean
        date
        integer
        timestamp
        numeric
        select
      }
    }
  }
`;

export const SYNC_RULE_TO_CRM = gql`
  query syncRuleToCrm {
    syncRuleToCrm {
      key
      value
    }
  }
`;

export const SYNC_RULE_FROM_CRM = gql`
  query syncRuleFromCrm {
    syncRuleFromCrm {
      key
      value
    }
  }
`;
