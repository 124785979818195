import React from 'react';

export const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <footer
      className="footer-container d-flex justify-content-between"
      style={props.user?.isFirstTimeUser ? { marginLeft: '0' } : {}}
    >
      <div>
        Koncert's sales engagement technology is protected by multiple patents,
        including U.S. Patent numbers 8594308, 8938058, and more, which are
        listed on our{' '}
        <a
          title="patents"
          href=" https://www.koncert.com/company/patents"
          target="_blank"
        >
          Patents
        </a>{' '}
        page. &copy; {year} - {props.productName}. All Rights Reserved.
      </div>
      <div id="social" className="d-flex justify-content-between">
        <div className="mr-2">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/connectleader/"
          >
            <i className="fab fa-facebook-square fa-xl"></i>
          </a>
        </div>
        <div className="mr-2">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/ConnectLeader"
          >
            <i className="fab fa-twitter-square fa-xl"></i>
          </a>
        </div>
        <div className="mr-2">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCIFy8UT0wZMLDcL1r7ZKpeQ"
          >
            <i className="fab fa-youtube-square fa-xl"></i>
          </a>
        </div>
        <div className="mr-2">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/connectleader"
          >
            <i className="fab fa-linkedin fa-xl"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};
