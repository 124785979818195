import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import CURRENT_USER_QUERY from './queries/CurrentUserQuery';
import { useDispatch } from 'react-redux';
import { signInUser } from '../store/actions/user.actions';
import authReducer from '../store/reducers/auth.reducers';
import { useSelector } from 'react-redux';
import Loading from './Layout/Loading';

const UserContext = React.createContext({});

export const UserProvider = (props) => {
  const [userQuery, { data, loading, error, refetch }] = useLazyQuery(
    CURRENT_USER_QUERY,
    {
      onCompleted: () => {
        handleUserCallback();
      },
      onError: () => {
        handleUserCallback();
      },
    }
  );

  useEffect(() => {
    userQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserCallback = () => {
    if (error?.networkError?.statusCode === 500) {
      throw new Error('500 Error');
    }

    if (
      error?.graphQLErrors &&
      ['INVALID_DATA', 'SYSTEM_ERROR'].includes(
        error.graphQLErrors[0]?.errorCode
      )
    ) {
      throw new Error('500 Error');
    }

    if (
      error?.message === 'GraphQL error: Invalid License' ||
      (!loading &&
        !error &&
        data?.me?.data &&
        data.me.data[0]?.userLicense &&
        !data.me.data[0].userLicense
          .split(',')
          .map((item) => item.trim())
          .includes('TC'))
    ) {
      throw new Error('Invalid license');
    }

    if (
      !loading &&
      !error &&
      (!data ||
        !data?.me ||
        !data?.me?.data ||
        !data?.me?.data[0] ||
        !data?.me?.data[0]?.userLicense)
    ) {
      throw new Error('500 Error');
    }
  };

  useDispatch(
    authReducer(
      useSelector((state) => state),
      signInUser('token', loading || error ? null : data?.me?.data[0])
    )
  );

  if (loading) {
    return (
      <UserContext.Provider value={{ error, loading }}>
        <Loading />
      </UserContext.Provider>
    );
  }

  // Not including the keys which are already present in the license server api response from the 'me' response
  const user =
    !error && data?.me?.data
      ? {
          userType: data.me.data[0].userType,
          startUpPageShowFlag: data.me.data[0].startUpPageShowFlag,
          reportFolder: data.me.data[0].reportFolder,
          talkerSessionMode: data.me.data[0].talkerSessionMode,
          talkerAgentType: data.me.data[0].talkerAgentType,
          talkerPhoneType: data.me.data[0].talkerPhoneType,
          transferPacingPerHour: data.me.data[0].transferPacingPerHour,
          prioritizationDelayMinutes:
            data.me.data[0].prioritizationDelayMinutes,
          transferUrl: data.me.data[0].transferUrl,
          lastProductType: data.me.data[0].lastProductType,
          lastProductUrl: data.me.data[0].lastProductUrl,
          rcUrl: data.me.data[0].rcUrl,
          bullhornUserType: data.me.data[0].bullhornUserType,
          isSimulatorUser: data.me.data[0].isSimulatorUser,
          allowCallMeJoinConference: data.me.data[0].allowCallMeJoinConference,
          phoneNumber: data.me.data[0].phoneNumber,
          isInternalUser: data.me.data[0].isInternalUser,
          callRecordingEnabledByManager:
            data.me.data[0].callRecordingEnabledByManager,
          callRecordingUserPermission:
            data.me.data[0].callRecordingUserPermission,
          callRecordingEnabledByUser:
            data.me.data[0].callRecordingEnabledByUser,
          callRecordingDisabledForTwoPartyConsent:
            data.me.data[0].callRecordingDisabledForTwoPartyConsent,
          enableScheduleCheck: data.me.data[0].enableScheduleCheck,
          allowAdaptilytics: data.me.data[0].allowAdaptilytics,
          allowModifyAdaptilyticsSettings:
            data.me.data[0].allowModifyAdaptilyticsSettings,
          allowDataEnrichment: data.me.data[0].allowDataEnrichment,
          allowModifyDataEnrichmentSettings:
            data.me.data[0].allowModifyDataEnrichmentSettings,
          token: data.me.data[0].token,
          cdPhoneMinutesUsed: data.me.data[0].cdPhoneMinutesUsed,
          showAccountsBy: data.me.data[0].showAccountsBy,
          isTrucadenceLeftmenuExpanded:
            data.me.data[0].isTrucadenceLeftmenuExpanded,
          accessTokenCreatedAt: data.me.data[0].accessTokenCreatedAt,
          accessTokenExpiresIn: data.me.data[0].accessTokenExpiresIn,
          outreachUserId: data.me.data[0].outreachUserId,
          gongUserId: data.me.data[0].gongUserId,
          lastExecutedDataListIds: data.me.data[0].lastExecutedDataListIds,
          hasCreateEmailTemplate: data.me.data[0].hasCreateEmailTemplate,
          hasCreateCadence: data.me.data[0].hasCreateCadence,
          salesloftUserId: data.me.data[0].salesloftUserId,
          salesloftGuid: data.me.data[0].salesloftGuid,
          showLeadScorePopup: data.me.data[0].showLeadScorePopup,
          remoteCoachCallListenUsers:
            data.me.data[0].remoteCoachCallListenUsers,
          remoteCoachCallListen: data.me.data[0].remoteCoachCallListen,
          createdBy: data.me.data[0].createdBy,
          modifiedBy: data.me.data[0].modifiedBy,
          createdDate: data.me.data[0].createdDate,
          modifiedDate: data.me.data[0].modifiedDate,
          zipwhipSessionKey: data.me.data[0].zipwhipSessionKey,
          isCreateAddProspect: data.me.data[0].isCreateAddProspect,
          defaultActivityFilter: data.me.data[0].defaultActivityFilter,
          isEmailPreviewEnabled: data.me.data[0].isEmailPreviewEnabled,
          emailFontFace: data.me.data[0].emailFontFace,
          emailFontSize: data.me.data[0].emailFontSize,
          defaultCadenceWidgets: data.me.data[0].defaultCadenceWidgets,
          openExternalLinksInNewWindow:
            data.me.data[0].openExternalLinksInNewWindow,
          apiEnabled: data.me.data[0].apiEnabled,
          exportProspects: data.me.data[0].exportProspects,
          isFirstTimeUser: data.me.data[0].isFirstTimeUser,
          signupUser: data.me.data[0].signupUser,
          allowSwitchingBetweenGeoLocations:
            data.me.data[0].allowSwitchingBetweenGeoLocations,
          associations: data.me.data[0].associations,
          regionId: data.me.data[0].regionId,
        }
      : null;

  return (
    <UserContext.Provider value={{ user, loading, error, refetch }}>
      {loading === false && user && props.children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
