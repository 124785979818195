import { toast } from 'react-toastify';
toast.configure();

export const notify = (
    message: string | JSX.Element,
    ToasterType:
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | 'default',
    id?: string
  ) => {
    const toastId = id ? id : message;
    const position:
      | 'top-right'
      | 'top-center'
      | 'top-left'
      | 'bottom-right'
      | 'bottom-center'
      | 'bottom-left' = 'top-right';
    const options: any = {
      type: ToasterType,
      position: position,
      toastId: toastId.toString(),
      pauseOnFocusLoss: false,
      pauseOnHover: true,
    };
    if (ToasterType === 'error') {
      options['autoClose'] = false;
    }
  
    const activeToastId = toast(message, options);
    if (toast.isActive(activeToastId)) {
      toast.update(toastId.toString(), {
        render: message,
        type: ToasterType,
        autoClose: ToasterType === 'error' ? null : false,
      });
    } else {
      toast(message, options);
    }
  };

  export const sortingByProperty = (key:string, order = 'asc') => {
    return function innerSort(a:any, b:any) {
      if (!a?.hasOwnProperty(key) || !b?.hasOwnProperty(key)) {
        return 0;
      }
  
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };
  
  export const sortingStringAscending = (arr:string[]) => {
    return arr?.sort((a, b) => a.localeCompare(b));
  };

  export const isManagerOrAdmin = (user:any)=>{
    return user?.admin === true || user?.manager === true || user?.isManagerUser==='Y' || user?.isAdminUser === 'Y'
  }

  export const isManager = (user:any)=>{
    return user?.manager === true || user?.isManagerUser==='Y' 
  }

  export const isAdmin = (user:any)=>{
    return user?.admin === true || user?.isAdminUser === 'Y'
  }
  export  const formatTextContent  = (text: string, replaceObj: any) => {
    if(text) {
    const textData: string[] = text.match(/\{{.*?\}}/g) || [];
    textData.forEach((data: string) => {
      const field = data.substr(2, data.length- 4);
      if(replaceObj[field]){
       text = text.replace(data, `{{${replaceObj[field]}}}`);
      }
     });
    }
     return text;
  };

  export const formatEmailBody = (emailBodyData:any) => {
    return emailBodyData &&
      emailBodyData
        .replaceAll('<br>', '')
        .replaceAll('<br/>', '')
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('&nbsp;', '')
        .split(/\s/)
        .join('');
  }

  export const formatEmailReplyBody = (emailReplyBodyData:any) => {
    return emailReplyBodyData &&
      emailReplyBodyData
        .replaceAll('<br>', '')
        .replaceAll('<br/>', '')
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replace(
          '<span style="font-family:Arial,sans-serif;font-size:14px">',
          ''
        )
        .replace('</span>', '')
        .replaceAll('&nbsp;', '')
        .replaceAll('<b>', '<strong>')
        .replaceAll('</b>', '</strong>')
        .split(/\s/)
        .join('');

  }
  
export const crmNameToCrmText = (crm: string) => {
  switch ((crm ?? '').toLowerCase()) {
    case 'salesforce':
    case 'salesforcesandbox':
      return 'Salesforce';

    case 'hubspot':
      return 'HubSpot';

    case 'zoho':
      return 'Zoho';

    default:
      throw new Error(`Invalid crm ${crm}`);
  }
};
