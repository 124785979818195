/*
 * @author vikrant-gembrill
 * @version V11.0
 */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isManagerOrAdmin } from '@koncert/shared-components';
import { Alert } from 'reactstrap';
import { useQuery, useLazyQuery } from '@apollo/client';
import LicenseUserContext from '../LicenseUserContext';
import {
  FETCH_BANNER_NOTIFICATIONS,
  CANCEL_BANNER_NOTIFICATION,
} from '../queries/NotificationsQuery';
import { showErrorMessage } from '../../util/index';
import useConfigurations from './hooks/UseConfigurations';

const BannerNotification = () => {
  const { user: licenseUser, loading: licenseUserLoading } = useContext(
    LicenseUserContext
  );
  const {
    data: configurationsData,
    error: configurationsError,
  } = useConfigurations();
  const org =
    !configurationsError && configurationsData?.configurations?.data[0];
  const currentUserId = licenseUserLoading || !licenseUser ? 0 : licenseUser.id;
  const [bannerList, setBannerList] = useState([]);
  const [bannerUniqueIds, setBannerUniqueIds] = useState({});
  const [holidayBannerVisible, setHolidayBannerVisible] = useState(true);
  const [holidayName, setHolidayName] = useState(null);
  const [
    showThirdPartyIntegrationsBanner,
    setShowThirdPartyIntegrationsBanner,
  ] = useState(false);

  useEffect(() => {
    setShowThirdPartyIntegrationsBanner(
      org?.crmType === 'hubspot' && !licenseUser?.hubspotId
    );
  }, [org, licenseUser]);

  // type of modules for banner notifications
  const modules = {
    email_account: [],
    email_signature: [],
    sync_service: [],
    email_template: [],
  };

  const {
    data: fetchBannerNotificationsData,
    loading: fetchBannerNotificationsLoading,
    error: fetchBannerNotificationsError,
    refetch: refetchBannerNotifications,
  } = useQuery(FETCH_BANNER_NOTIFICATIONS, {
    pollInterval: 120000,
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: currentUserId,
      modules: `filter[module]=:[${[...Object.keys(modules)]}]`,
    },
    skip: !licenseUser,
    onCompleted: (response) => {
      setBannerList([]);
      createBannerNotificationsList(response);
    },
    onError: (error) => {
      const technicalDetails = {
        apiEndPoint: 'bannerNotifications',
        apiInitiatedFrom: 'BannerNotifications',
      };
      showErrorMessage(
        error,
        'Sorry! Failed to fetch banner notifications',
        fetchBannerNotificationsData,
        'fetch_banner_notifications',
        technicalDetails
      );
    },
  });

  const [cancelBannerNotification] = useLazyQuery(CANCEL_BANNER_NOTIFICATION, {
    onCompleted: (response) => {
      refetchBannerNotifications();
    },
  });

  const onDismiss = (bannerId) => {
    cancelBannerNotification({
      variables: { bannerId: bannerId, input: { isActive: false } },
    });
  };

  const createBannerNotificationsList = (response) => {
    // set holiday name
    setHolidayName(
      response?.notifications?.includedAssociations?.holiday?.name
    );
    // creating data for each module
    if (response?.notifications?.data) {
      response.notifications.data.forEach((item) => {
        modules[item.module].push(item);
      });
    }

    // creating list for each module
    Object.keys(modules).forEach((item) => {
      if (item === 'sync_service' && modules[item].length > 0) {
        modules[item].forEach((subItem, index) => {
          // saving the first id as duplicated banner should not be shown. also the user can click close icon and banner should disappear in frontend.
          if (
            (index === 0 && !bannerUniqueIds[item]) ||
            (index === 0 && subItem['id'] === bannerUniqueIds[item])
          ) {
            setBannerUniqueIds((prevState) => {
              return {
                ...prevState,
                [item]: subItem['id'],
              };
            });

            const bannerItem = (
              <Alert
                isOpen={true}
                color="warning"
                className="rounded-0"
                key={subItem['id'] + '_' + index}
              >
                <div className="float-right">
                  <i
                    className="fas fa-times-circle pointer text-right text-dark"
                    onClick={(e) => {
                      onDismiss(subItem['id']);
                    }}
                  ></i>
                </div>
                <div className="d-flex justify-content-center text-dark">
                  {subItem['issueNotificationInformation']}
                </div>
              </Alert>
            );
            // only showing one instance of the notification, as there can be multiple instances of the notification
            setBannerList((prevState) => {
              return [...prevState, bannerItem];
            });
          }
        });
      } else if (item === 'email_template' && modules[item].length > 0) {
        [
          ...new Map(
            modules[item].map((item) => [
              item['issueNotificationInformation'],
              item,
            ])
          ).values(),
        ].forEach((subItem, index) => {
          const bannerItem = (
            <Alert
              isOpen={true}
              color="warning"
              className="rounded-0"
              key={subItem['id'] + '_' + index}
            >
              <div className="float-right">
                <i
                  className="fas fa-times-circle pointer text-right text-dark"
                  onClick={(e) => {
                    onDismiss(subItem['id']);
                  }}
                ></i>
              </div>
              <div className="d-flex justify-content-center text-dark">
                {subItem['issueNotificationInformation']}
              </div>
            </Alert>
          );
          setBannerList((prevState) => {
            return [...prevState, bannerItem];
          });
        });
      }
    });
  };

  return (
    <>
      {/* banner for holiday */}
      {holidayName?.length > 0 && (
        <Alert
          isOpen={holidayBannerVisible}
          color="warning"
          key="holidayBanner"
        >
          <div
            className="float-right"
            onClick={(e) => {
              setHolidayBannerVisible(false);
            }}
          >
            <i className="fas fa-times-circle pointer text-right text-dark"></i>
          </div>
          <div className="d-flex justify-content-center text-dark">
            Emails are on hold due to holiday, {holidayName}
          </div>
        </Alert>
      )}

      {showThirdPartyIntegrationsBanner && (
        <Alert isOpen={showThirdPartyIntegrationsBanner} color="warning">
          <div
            className="float-right"
            onClick={(e) => {
              setShowThirdPartyIntegrationsBanner(false);
            }}
          >
            <i className="fas fa-times-circle pointer text-right text-dark"></i>
          </div>
          {isManagerOrAdmin(licenseUser) ? (
            <Link
              to="/settings/thirdPartyIntegrations"
              className="d-flex justify-content-center text-dark"
            >
              Please complete configuring and assigning users in Third Party
              Integrations
            </Link>
          ) : (
            <div className="d-flex justify-content-center text-dark">
              Please complete configuring and assigning users in Third Party
              Integrations
            </div>
          )}
        </Alert>
      )}

      {/* banners for other modules */}
      {!fetchBannerNotificationsLoading &&
        !fetchBannerNotificationsError &&
        bannerList}
    </>
  );
};

export default BannerNotification;
