import gql from 'graphql-tag';

export const CONTACTCOVERAGEAGGREGATION = gql`
  query contactCoverageAggregation(
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
    $sendersId: [ID!]
  ) {
    contactCoverageAggregation(
      format: $format
      startDate: $startDate
      endDate: $endDate
      sendersId: $sendersId
    ) {
      id
      startDate
      endDate
      data
      totalCount
      totalContactCount
      totalDnc
    }
  }
`;

export default CONTACTCOVERAGEAGGREGATION;
