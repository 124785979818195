/* eslint-disable @typescript-eslint/camelcase */
import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  ConfirmModal,
  isManagerOrAdmin,
  KoncertLogoSmall as KoncertLogo,
  LogoSmall,
  PageLoader,
  PhoneIconGif,
  QuickAction,
  useToggle,
} from '@koncert/shared-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  Tooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import ClctiClient from '../../apollo/ClctiClient';
import * as actions from '../../store/actions/actions';
import {
  capitalizeFirstLetter,
  handleLogout,
  notify,
  showErrorMessage,
} from '../../util/index';
import useConfigurations from '../Common/hooks/UseConfigurations';
import useFieldsData from '../Common/hooks/useFieldsData';
import useLookupData from '../Common/hooks/useLookupData.ts';
import useMailMergeVariables from '../Common/hooks/useMailMergeVariables';
import SelectProspectModal from '../Pages/Emails/SelectProspectModal';
import AddNote from '../Pages/Prospects/AddNote';
import AddProspectModal from '../Pages/Prospects/AddProspectModal';
import LogATaskModal from '../Pages/Prospects/LogATaskModal';
import EmailsModal from '../Pages/ToDo/EmailsModal';
import { CD_SESSION_LOG } from '../queries/ClickDialerQuery';
import {
  FETCH_NOTIFICATIONS,
  FETCH_UNREAD_NOTIFICATIONS,
  MARK_AS_READ_ALL_NOTIFICATIONS,
  MARK_AS_READ_SOME_NOTIFICATIONS,
} from '../queries/NotificationsQuery';
import FETCH_REMINDERS_LIST_QUERY from '../queries/RemindersQuery.ts';
import {
  CREATE_NOTES_QUERY,
  CREATE_PROSPECT_QUERY,
} from '../queries/ProspectsQuery';
import UserContext from '../UserContext';
import LicenseUserContext from '../LicenseUserContext';
import Notifications from './Notifications';
import useNotificationCount from '../../custom-hooks/useNotificationCount';
import useOrgLicense from '../Common/hooks/useOrgLicense';

const Header = ({ actions, history, ...props }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    sessionId,
    totalVmCount,
    sessionStartTime,
    reportSessionId,
    clSessionId,
    talkerIsOnCall,
    isUnSavedCallDetailsExist,
  } = useSelector((state) => state.clickDialer);
  const logoutChannel = new BroadcastChannel('logout');
  const { user, loading: userLoading, error } = useContext(UserContext);
  const { user: licenseUser, loading: licenseUserLoading } = useContext(
    LicenseUserContext
  );
  const { availableProducts } = useOrgLicense();
  // using custom hook which manages fetching notification count in case multiple tabs are open.
  const {
    unReadNotifyCount,
    fetchNotificationsCountFromHook,
    fetchNotificationsCount,
  } = useNotificationCount(user);

  const [notificationDetails, setNotificationDetails] = useState();
  const [notificationDayFilter, setNotificationDayFilter] = useState();
  const outcomes = encodeURIComponent(
    ':[Calls,Sent,Opened,Replied,Bounced,Links Clicked,Opt-out,Failed]'
  );
  const [notificationOutcomeFilter, setNotificationOutcomeFilter] = useState(
    `filter[outcome]=${outcomes}`
  );
  const defaultNotificationsLimit = 25;
  const [limit, setLimit] = useState(defaultNotificationsLimit);
  const [offset, setOffset] = useState(0);
  const [lastSelectedDate, setLastSelectedDate] = useState('unread');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const currentUserId = licenseUser?.id;
  const { data: configurationsData } = useConfigurations();
  const { data: fieldsData } = useFieldsData();
  const { mailMergeVariables } = useMailMergeVariables();
  const org = configurationsData?.configurations?.data[0];
  const fieldsList = fieldsData?.fields?.data;
  const requestApiUrl = org?.cdApiUrl + '/ctiservice';
  const { lookup } = useLookupData();

  const { user: currentAuth0User, logout } = useAuth0();
  const [showEndSessionConfirmModal, toggleEndSessionConfirmModal] = useToggle(
    false
  );
  const [endSessionLoading, toggleEndSessionLoading] = useToggle(false);
  // fields required for send email option
  const actionTypes = {
    EMAIL: 'email',
    FOLLOWUP: 'followup',
    NOTES: 'notes',
  };

  if (user?.isCreateAddProspect) {
    actionTypes.ADD_PROSPECT = 'add_prospect';
  }
  const [selectedProspectId, setSelectedProspectId] = useState();
  const [dropdownUserId, setDropdownUserId] = useState(currentUserId);
  const [showSelectProspectModal, toggleSelectProspectModal] = useState(false);
  const [actionType, setActionType] = useState(actionTypes.EMAIL);
  const [showEmailsModal, toggleEmailsModal] = useToggle(false);
  const [showFollowupModal, setShowFollowupModal] = useToggle(false);
  const [showAddNoteModal, setShowAddNoteModal] = useToggle(false);
  const [attachment, setAttachment] = useState({});
  const [showAddProspectModal, setShowAddProspectModal] = useToggle(false);
  const [remindersCount, setRemindersCount] = useState(0);

  const baseAppURL = process.env.REACT_APP_BASE_APP_URL.endsWith('/')
    ? process.env.REACT_APP_BASE_APP_URL.concat('profile')
    : process.env.REACT_APP_BASE_APP_URL.concat('/profile');

  const getActionIconAndTitle = (type) => {
    let actionIcon = null;
    let actionTitle = null;
    let actionLabel = null;
    switch (type) {
      case actionTypes.EMAIL:
        actionIcon = 'fas fa-envelope';
        actionTitle = 'Send Email';
        actionLabel = 'Email';
        break;
      case actionTypes.FOLLOWUP:
        actionIcon = 'far fa-edit';
        actionTitle = 'Create followup';
        actionLabel = 'Task';
        break;
      case actionTypes.NOTES:
        actionIcon = 'fas fa-clipboard';
        actionTitle = 'Add a note';
        actionLabel = 'Note';
        break;
      case actionTypes.ADD_PROSPECT:
        actionIcon = 'fas fa-user-plus';
        actionTitle = 'Add a Prospect';
        actionLabel = 'Prospect';
        break;
      default:
        break;
    }
    return {
      actionIcon,
      actionTitle,
      actionLabel,
    };
  };

  const [saveSessionLog, { data: sessionLogData }] = useLazyQuery(
    CD_SESSION_LOG,
    {
      onError: (response) => {
        const technicalDetails = {
          apiEndPoint: 'prospects/saveCDSessionLogToCRM',
          apiInitiatedFrom: 'Header',
        };
        showErrorMessage(
          response,
          'Failed save the CD Session Logs.',
          sessionLogData,
          'session_log',
          technicalDetails
        );
      },
    }
  );

  // save notes request
  const [addNotes, { data: notesData, loading: notesLoading }] = useLazyQuery(
    CREATE_NOTES_QUERY,
    {
      onCompleted: (response) => addNotesRequestCallback(response, true),
      onError: (response) => {
        const technicalDetails = {
          apiEndPoint: 'notes',
          apiInitiatedFrom: 'Header',
        };
        showErrorMessage(
          response,
          'Failed to add notes.',
          notesData,
          'add_notes',
          technicalDetails
        );
      },
    }
  );

  const addNotesRequestCallback = (response, requestSuccess) => {
    if (requestSuccess && response) {
      notify('Notes added successfully!', 'success');
      setShowAddNoteModal(false);
    }
  };

  const getRemindersQry = (pageLimit, pageOffset) => {
    const filterQuery = [];
    filterQuery.push(`page[limit]=${pageLimit}`);
    filterQuery.push(`page[offset]=${pageOffset}`);
    filterQuery.push(`filter[user][id]=${currentUserId}`);
    return filterQuery.join('&');
  };

  const { data: remindersData } = useQuery(FETCH_REMINDERS_LIST_QUERY, {
    variables: {
      remindersFilter: getRemindersQry(limit, offset),
    },
    pollInterval: 2 * 60 * 1000, // 2 mins interval
    notifyOnNetworkStatusChange: true,
    skip: !currentUserId,
    onCompleted: (response) => {
      const totalCount = response?.reminders?.paging?.totalCount;
      if (totalCount && totalCount > 0 && totalCount !== remindersCount) {
        const windowName = 'Reminders';
        const windowParams =
          'left=0,width=550,height=400,top=0,menubar=0,scrollbars=1,resizable =1,titlebar=0,status=0,toolbar=0';
        const reminderUrl = `${window.location.origin}/reminders`;
        const newWin = window.open(reminderUrl, windowName, windowParams);
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          //POPUP BLOCKED NOTIFICATION
          notify(
            'Pop-ups and redirects are blocked by browser for this site. Please enable Pop-ups and redirects in settings',
            'error'
          );
        } else {
          newWin.focus();
        }
        setRemindersCount(totalCount);
      }
    },
    onError: (response) => {
      const technicalDetails = {
        apiEndPoint: 'tasks/reminder',
        apiInitiatedFrom: 'Header',
      };
      showErrorMessage(
        response,
        'Failed to fetch reminders data',
        remindersData,
        'fetch_reminders_count',
        technicalDetails
      );
    },
  });

  // Add prospect to cadence request
  const [
    addProspect,
    { data: addProspectData, loading: addProspectLoading },
  ] = useLazyQuery(CREATE_PROSPECT_QUERY, {
    onCompleted: (response) => handleAddProspectRequestCallback(response, true),
    onError: (response) => handleAddProspectRequestCallback(response),
  });

  const handleAddProspectRequestCallback = (response, requestSuccess) => {
    if (requestSuccess) {
      if (response.prospect.errors !== undefined) {
        notify(response.prospect.errors[0].message, 'error');
      } else if (response?.prospect?.data?.[0]?.status === 'error') {
        notify(
          `Failed to add this prospect. ${
            response.prospect.data[0].reason &&
            'Reason: ' + capitalizeFirstLetter(response.prospect.data[0].reason)
          }`,
          'error'
        );
      } else {
        setShowAddProspectModal(false);
        notify('Prospect added successfully!', 'success');
        if (response?.prospect?.data[0]?.prospectsAssigned) {
          notify('Prospect assigned to cadence successfully!', 'success');
        } else if (response?.prospect?.data[0]?.cadenceError) {
          notify(response.prospect.data[0].cadenceError.message, 'error');
        }
      }
    } else {
      const technicalDetails = {
        apiEndPoint: 'prospects',
        apiInitiatedFrom: 'Header',
      };
      showErrorMessage(
        response,
        'Failed to add prospect(s)',
        addProspectData,
        'add_prospects',
        technicalDetails
      );
    }
  };

  const handleEndSession = () => {
    //If user is on ongoing call the notify user
    if (talkerIsOnCall) {
      notify(
        'WARNING (CD002): You are currently on a call. Please hang up your call and then end your Session',
        'error'
      );
      return null;
    }

    // If user is made call and the performed Leave VM or Hangup but not the saved call details. In the case notify user to save the call details before ending the session
    if (isUnSavedCallDetailsExist) {
      notify(
        'WARNING (CD004): Please save the call details and then try again.',
        'error'
      );
      return;
    }
    toggleEndSessionLoading(true);

    const sessionDetails = new URLSearchParams();
    sessionDetails.append('session_id', sessionId);
    sessionDetails.append('total_vm_left', totalVmCount);
    sessionDetails.append('session_end_time', new Date().toISOString());
    sessionDetails.append('end_session_invokedby', 'TrueCadence');
    sessionDetails.append('log_type', 'FINAL');
    sessionDetails.append('end_phone_call', 'Y');
    sessionDetails.append('session_start_time', sessionStartTime);

    ClctiClient(sessionDetails, requestApiUrl + '/endsession')
      .then((res) => {
        if (
          location.pathname.includes('/prospects/list') &&
          location.pathname !== '/prospects/list'
        ) {
          dispatch(actions.resetClickDialerData('refreshThePage', true));
        } else {
          dispatch(actions.resetClickDialerData());
        }
        sessionStorage.removeItem('sessionId');
        // Saving the session end time
        if (lookup?.enable_crm_log_dialing_session === 'Y' && reportSessionId) {
          saveSessionLog({
            variables: {
              input: {
                reportSessionId,
                clSessionId,
              },
            },
          });
        }
        toggleEndSessionConfirmModal(false);
        toggleEndSessionLoading(false);
      })
      .catch((error) => {
        notify(error.message, 'error');
        toggleEndSessionConfirmModal(false);
        toggleEndSessionLoading(false);
      });
  };

  useEffect(
    () => {
      if (props?.settings?.isCollapsed === user.isTrucadenceLeftmenuExpanded) {
        actions.toggleSetting('isCollapsed');
        actions.toggleSetting('isCollapsedText');
        resize();
      }
      if (props?.settings?.asideToggled === user.isTrucadenceLeftmenuExpanded) {
        actions.toggleSetting('asideToggled');
      }
    },
    // eslint-disable-next-line
    []
  );

  // calling the function to fetch notifications count in setInterval at every 2 mins.
  useEffect(() => {
    const id = setInterval(() => {
      if (fetchNotificationsCountFromHook) {
        fetchNotificationsCountFromHook();
      }
    }, 120000);

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    fetchNotifications,
    { loading: fetchNotificationsLoading, error: fetchNotificationsError },
  ] = useLazyQuery(FETCH_NOTIFICATIONS, {
    variables: {
      limit: limit,
      offset: offset,
      notificationDayFilter: notificationDayFilter,
      notificationOutcomeFilter: notificationOutcomeFilter,
    },
    onCompleted: (response) => {
      setNotificationDetails(response?.notifications);
      markAsReadSomeNotifications({
        variables: {
          limit: limit,
          offset: offset,
          notificationDayFilter: notificationDayFilter,
          notificationOutcomeFilter: notificationOutcomeFilter,
          input: {},
        },
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  const [
    fetchUnreadNotifications,
    {
      loading: fetchUnreadNotificationsLoading,
      error: fetchUnreadNotificationsError,
      refetch: refetchUnreadNotifications,
    },
  ] = useLazyQuery(FETCH_UNREAD_NOTIFICATIONS, {
    variables: {
      limit: limit,
      offset: offset,
      unreadStatus: 'false',
      notificationOutcomeFilter: notificationOutcomeFilter,
    },
    onCompleted: (response) => {
      setNotificationDetails(response.notifications);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [markAsReadSomeNotifications] = useLazyQuery(
    MARK_AS_READ_SOME_NOTIFICATIONS,
    {
      onCompleted: (response) => {
        if (fetchNotificationsCount) {
          fetchNotificationsCount();
        }
      },
    }
  );

  const [
    markAsReadAllNotifications,
    { loading: markAsReadAllNotificationsLoading },
  ] = useLazyQuery(MARK_AS_READ_ALL_NOTIFICATIONS, {
    onCompleted: (response) => {
      // mark notifications as read in batches of 500
      if (response?.notifications?.paging?.totalCount > 0) {
        markAsReadAllNotifications({
          variables: {
            limit: 500,
            offset: offset,
            notificationOutcomeFilter: notificationOutcomeFilter,
            input: {},
          },
        });
      } else {
        if (fetchNotificationsCount) {
          fetchNotificationsCount();
        }
        // refetch unread notifications only if unread is selected in date filter
        if (lastSelectedDate === 'unread' && refetchUnreadNotifications) {
          refetchUnreadNotifications();
        }
      }
    },
  });

  const notificationsLoading =
    lastSelectedDate === 'unread'
      ? fetchUnreadNotificationsLoading
      : fetchNotificationsLoading;
  const notificationsError =
    lastSelectedDate === 'unread'
      ? fetchUnreadNotificationsError
      : fetchNotificationsError;

  const showMoreNotifications = () => {
    setOffset(offset + 1);
    if (lastSelectedDate === 'unread') {
      fetchUnreadNotifications({
        variables: {
          limit: limit,
          offset: offset + 1,
          unreadStatus: 'false',
          notificationOutcomeFilter: notificationOutcomeFilter,
        },
      });
    } else {
      fetchNotifications({
        variables: {
          limit: limit,
          offset: offset + 1,
          notificationDayFilter: notificationDayFilter,
          notificationOutcomeFilter: notificationOutcomeFilter,
        },
      });
    }
  };

  const handleMarkAsReadAllNotifications = () => {
    markAsReadAllNotifications({
      variables: {
        limit: 500,
        offset: offset,
        notificationOutcomeFilter: notificationOutcomeFilter,
        input: {},
      },
    });
  };

  const onChangeDropDown = (value, filter) => {
    setOffset(0);
    setLimit(defaultNotificationsLimit);
    setLastSelectedDate(filter === 'createdDate' ? value : lastSelectedDate);
    // if unread is selected in dateRange
    if (
      (filter === 'createdDate' && value === 'unread') ||
      (filter === 'outcome' && lastSelectedDate === 'unread')
    ) {
      let outcomeFilter;
      if (filter === 'outcome') {
        const outcome = encodeURIComponent(':[' + value + ']');
        outcomeFilter = `filter[outcome]=${outcome}`;
        setNotificationOutcomeFilter(outcomeFilter);
      }
      fetchUnreadNotifications({
        variables: {
          limit: limit,
          offset: 0,
          unreadStatus: 'false',
          notificationOutcomeFilter:
            outcomeFilter !== undefined
              ? outcomeFilter
              : notificationOutcomeFilter,
        },
      });
    } else {
      let dayFilter;
      let outcomeFilter;
      if (filter === 'createdDate') {
        switch (value) {
          case 'Current week': {
            setNotificationDayFilter(getCurrentWeekdays());
            dayFilter = getCurrentWeekdays();
            break;
          }
          case 'Today': {
            dayFilter = getToday();
            setNotificationDayFilter(dayFilter);
            break;
          }
          case 'Yesterday': {
            dayFilter = getYesterDay();
            setNotificationDayFilter(dayFilter);
            break;
          }
          default: {
            dayFilter = getDateRangeFilters(value);
            setNotificationDayFilter(dayFilter);
          }
        }
      } else if (filter === 'outcome') {
        const outcome = encodeURIComponent(':[' + value + ']');
        outcomeFilter = `filter[outcome]=${outcome}`;
        setNotificationOutcomeFilter(outcomeFilter);
      }
      fetchNotifications({
        variables: {
          limit: limit,
          offset: 0,
          notificationDayFilter:
            dayFilter !== undefined ? dayFilter : notificationDayFilter,
          notificationOutcomeFilter:
            outcomeFilter !== undefined
              ? outcomeFilter
              : notificationOutcomeFilter,
        },
      });
    }
  };

  const getCurrentWeekdays = () => {
    const currentDay1 = new Date();
    const currentDay2 = new Date();
    const day = currentDay1.getDay();
    let startDate = currentDay1.setDate(currentDay1.getDate() - day);
    startDate = new Date(startDate).toISOString();
    let endDate = currentDay2.setDate(currentDay2.getDate() + (6 - day));
    endDate = new Date(endDate).toISOString();
    const currentWeek = `filter[createdDate]=>=${startDate}&filter[createdDate]=<=${endDate}`;
    return currentWeek;
  };
  const getYesterDay = () => {
    const currentDay = new Date();
    const createdDate = currentDay.setDate(currentDay.getDate() - 1);
    const date = new Date(createdDate).toISOString();
    const fromDate = date.split('T')[0] + 'T00:00:00Z';
    const toDate = date.split('T')[0] + 'T23:59:59Z';
    return `filter[createdDate]=>=${fromDate}&filter[createdDate]=<=${toDate}`;
  };
  const getToday = () => {
    const today = moment().toISOString();
    const fromDate = today.split('T')[0] + 'T00:00:00Z';
    const toDate = today.split('T')[0] + 'T23:59:59Z';
    return `filter[createdDate]=>=${fromDate}&filter[createdDate]=<=${toDate}`;
  };
  const getDateRangeFilters = (value) => {
    const current = new Date();
    let createdDate;
    switch (value) {
      case '15 mins':
        createdDate = current.setMinutes(current.getMinutes() - 15);
        break;
      case '1 hour':
        createdDate = current.setHours(current.getHours() - 1);
        break;
      case '3 hours':
        createdDate = current.setHours(current.getHours() - 3);
        break;
      case '6 hours':
        createdDate = current.setHours(current.getHours() - 6);
        break;
      case 'Last 7 days':
        createdDate = current.setDate(current.getDate() - 7);
        break;
      default:
    }
    const startDate = new Date(createdDate).toISOString();
    const dateRangeFilter = `filter[createdDate]=>=${startDate}&filter[createdDate]=<=${moment().toISOString()}`;
    return dateRangeFilter;
  };

  const changeNotificationShow = () => {
    // to refetch notification on button click
    onChangeDropDown(lastSelectedDate, 'createdDate');
  };

  const handleQuickActionClick = (flag) => {
    setActionType(flag);
    if (flag === actionTypes.ADD_PROSPECT) {
      setShowAddProspectModal(true);
    } else {
      toggleSelectProspectModal(true);
    }
  };

  const handleOpenQuickActionModal = () => {
    switch (actionType) {
      case actionTypes.EMAIL:
        toggleEmailsModal(true);
        break;
      case actionTypes.FOLLOWUP:
        setShowFollowupModal(true);
        break;
      case actionTypes.NOTES:
        setShowAddNoteModal(true);
        break;
      default:
        break;
    }
  };

  const toggleCollapsed = (e) => {
    e.preventDefault();
    actions.toggleSetting('isCollapsed');
    actions.toggleSetting('isCollapsedText');
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    actions.toggleSetting('asideToggled');
  };

  const resize = () => {
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  };

  if (userLoading || licenseUserLoading) return null;
  if (error) {
    // eslint-disable-next-line
    throw 'Sorry! Failed to fetch user details.';
  }

  if (!user || !licenseUser) return <PageLoader />;

  /* ----- Code to link user auth0 accounts -begin ----- */
  const _avatar = currentAuth0User
    ? currentAuth0User.picture || Avatar
    : Avatar;

  return (
    // z-index is used to display it over toast messages
    <header
      className="topnavbar-wrapper"
      style={{
        zIndex: 99999,
      }}
    >
      {/* START Top Navbar */}
      <nav className="navbar topnavbar">
        {/* START navbar header */}
        <div className="navbar-header">
          <div className="d-flex align-items-center pl-4">
            <a className="text-decoration-none navbar-brand text-left" href="/">
              <div className="d-flex align-items-center py-1">
                {org?.enableCustomLogo && org?.customLogoUrl ? (
                  <img
                    src={`${process.env.REACT_APP_CADENCE_API_URL}public/logo?fileName=${org?.customLogoUrl}&customLogo=true`}
                    alt="Logo"
                    style={{
                      maxWidth: '134px',
                      maxHeight: '30px',
                    }}
                  />
                ) : (
                  <>
                    <img
                      className="mr-2"
                      src={LogoSmall}
                      alt="Logo"
                      width="30"
                    />
                    <span className="text-sans-serif text-color-koncert-white h3 mb-0">
                      Cadence
                    </span>
                  </>
                )}
              </div>
            </a>
          </div>
        </div>
        {/* END navbar header */}

        {/* START Left navbar */}
        {!user?.isFirstTimeUser && (
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <span
                className="nav-link d-none d-md-block d-lg-block d-xl-block pointer"
                onClick={toggleCollapsed}
              >
                <em className="fas fa-bars"></em>
              </span>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <span
                className="nav-link sidebar-toggle d-md-none pointer"
                onClick={toggleAside}
              >
                <em className="fas fa-bars"></em>
              </span>
            </li>
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
              <DropdownToggle
                nav
                className="dropdown-toggle-nocaret"
                onClick={() => {
                  changeNotificationShow();
                }}
              >
                <i className="icon-bell pointer"></i>
                {unReadNotifyCount > 0 && (
                  <span className="badge badge-primary bg-gradient-brand">
                    {unReadNotifyCount > 1000 ? '999+' : unReadNotifyCount}
                  </span>
                )}
                {unReadNotifyCount === -1 && (
                  <i
                    className="fas fa-info-circle text-danger"
                    title="Sorry! Failed to fetch unread notification count."
                  ></i>
                )}
              </DropdownToggle>
              {/* START Dropdown menu */}
              <Notifications
                data={notificationDetails}
                error={notificationsError}
                loading={notificationsLoading}
                onChangeDropDown={onChangeDropDown}
                showMoreNotifications={showMoreNotifications}
                handleMarkAsReadAllNotifications={
                  handleMarkAsReadAllNotifications
                }
                markAsReadAllNotificationsLoading={
                  markAsReadAllNotificationsLoading
                }
                fields={fieldsList}
                // if unReadNotifyCount is 0, mark all as read button will be hidden
                unReadNotifyCount={unReadNotifyCount}
              />
              {/* END Dropdown menu */}
            </UncontrolledDropdown>
            {sessionId && sessionStartTime && (
              <li
                className="nav-item"
                onClick={() => toggleEndSessionConfirmModal(true)}
                title="You are currently on a click dialer session"
              >
                <span className="nav-link d-none d-md-block d-lg-block d-xl-block pointer p-2">
                  <img
                    src={PhoneIconGif}
                    className="img-fluid"
                    title="You are currently on a click dialer session"
                    width={30}
                    alt="Phone"
                  />
                </span>
              </li>
            )}
            {showEndSessionConfirmModal && (
              <ConfirmModal
                confirmBtnIcon="fas fa-ban"
                confirmBtnText="End session"
                confirmBtnColor="danger"
                handleCancel={() => toggleEndSessionConfirmModal(false)}
                handleConfirm={() => handleEndSession()}
                showConfirmBtnSpinner={endSessionLoading}
                showConfirmModal={showEndSessionConfirmModal}
                header="End session"
              >
                <div className="text-center">
                  You are currently on a click dialer session. Are you sure you
                  want to end your session?
                </div>
              </ConfirmModal>
            )}
            {/* END Alert menu */}
          </ul>
        )}
        {/* END Left navbar */}

        {/* START Right Navbar */}

        {!user?.isFirstTimeUser && (
          <ul className="navbar-nav flex-row">
            {(org?.enableCustomLogo && !org?.customLogoUrl) ||
              (!org?.enableCustomLogo && (
                <li className="text-white d-flex align-items-center">
                  {/* This is Largers Devices */}
                  <div
                    className="text-truncate d-none d-md-block"
                    style={{
                      maxWidth: '360px',
                    }}
                  >
                    <span className="mr-1">
                      Welcome,{' '}
                      {`${licenseUser?.name}${
                        org?.clientName ? ', ' + org.clientName : ''
                      }`}
                    </span>
                  </div>
                  {/* This is Smaller Devices */}
                  <div
                    className="text-truncate d-none d-md-none d-sm-block"
                    style={{
                      maxWidth: '100px',
                    }}
                  >
                    <span className="mr-1">
                      Welcome,{' '}
                      {`${licenseUser?.name}${
                        org?.clientName ? ', ' + org.clientName : ''
                      }`}
                    </span>
                  </div>
                </li>
              ))}
            <UncontrolledDropdown
              nav
              inNavbar
              className="dropdown-list d-flex align-items-center"
            >
              <DropdownToggle nav className="dropdown-toggle-nocaret">
                <img
                  className="mr-2 float-left"
                  src={KoncertLogo}
                  alt="KoncertLogo"
                  height="20"
                />
                <em className="fa fa-th"></em>
              </DropdownToggle>
              <DropdownMenu right className="animated bounceIn px-2">
                <span className="dropdown-item-text text-bold py-2">Apps</span>
                <DropdownItem>
                  <Row>
                    <Col className="pb-2 pointer">
                      <div
                        onClick={() => {
                          const baseAppUrl = baseAppURL;
                          let workspaceWin;
                          if (user?.openExternalLinksInNewWindow) {
                            workspaceWin = window.open(baseAppUrl+'?source=Dialers', 'baseapp');
                          } else {
                            workspaceWin = window.open(baseAppUrl+'?source=Dialers', '_self');
                          }

                          if (
                            !workspaceWin ||
                            workspaceWin.closed ||
                            typeof workspaceWin.closed == 'undefined'
                          ) {
                            //POPUP BLOCKED NOTIFICATION
                            notify(
                              'Pop-ups and redirects are blocked by browser for this site. Please enable Pop-ups and redirects in browser settings',
                              'error'
                            );
                          } else {
                            workspaceWin.focus();
                          }
                        }}
                      >
                        <i className="fas fa-address-card mx-2"></i>
                        Profile
                      </div>
                    </Col>
                  </Row>
                </DropdownItem>
                {(licenseUser?.products
                  .split(',')
                  .map((item) => item.trim())
                  .some((item) => ['CD', 'AIDML', 'AIDSL'].includes(item)) ||
                  (isManagerOrAdmin(licenseUser) &&
                    availableProducts.some((item) =>
                      ['ADM', 'ADS', 'CD'].includes(item)
                    ))) && (
                  <DropdownItem>
                    <Row>
                      <Col className="pb-2 pointer">
                        <div
                          onClick={() => {
                            const dialersUrl =
                              process.env.REACT_APP_DIALERS_APP_URL;
                            let newWin;
                            if (user?.openExternalLinksInNewWindow) {
                              newWin = window.open(dialersUrl, 'dialers');
                            } else {
                              newWin = window.open(dialersUrl, '_self');
                            }

                            if (
                              !newWin ||
                              newWin.closed ||
                              typeof newWin.closed == 'undefined'
                            ) {
                              //POPUP BLOCKED NOTIFICATION
                              notify(
                                'Pop-ups and redirects are blocked by browser for this site. Please enable Pop-ups and redirects in browser settings',
                                'error'
                              );
                            } else {
                              newWin.focus();
                            }
                          }}
                        >
                          <i className="fas fa-address-book mx-2"></i>
                          Modern Dialers
                        </div>
                      </Col>
                    </Row>
                  </DropdownItem>
                )}
                {(licenseUser?.products
                  .split(',')
                  .map((item) => item.trim())
                  .some((item) => ['TD', 'PD'].includes(item)) ||
                  (isManagerOrAdmin(licenseUser) &&
                    availableProducts.some((item) =>
                      ['TD', 'PD'].includes(item)
                    ))) && (
                  <DropdownItem>
                    <Row>
                      <Col className="pb-2 pointer">
                        <div
                          onClick={() => {
                            const dialersUrl = org?.powerDialerUrl;
                            let newWin;
                            if (user?.openExternalLinksInNewWindow) {
                              newWin = window.open(dialersUrl, 'dialers');
                            } else {
                              newWin = window.open(dialersUrl, '_self');
                            }

                            if (
                              !newWin ||
                              newWin.closed ||
                              typeof newWin.closed == 'undefined'
                            ) {
                              //POPUP BLOCKED NOTIFICATION
                              notify(
                                'Pop-ups and redirects are blocked by browser for this site. Please enable Pop-ups and redirects in browser settings',
                                'error'
                              );
                            } else {
                              newWin.focus();
                            }
                          }}
                        >
                          <i className="fas fa-address-book mx-2"></i>
                          Classic Dialers
                        </div>
                      </Col>
                    </Row>
                  </DropdownItem>
                )}
                <span className="dropdown-item-text text-bold py-2 border-top border-dark">
                  Quick Actions
                </span>
                <Row className="mx-1">
                  {Object.keys(actionTypes).map((action, index) => {
                    const {
                      actionIcon,
                      actionTitle,
                      actionLabel,
                    } = getActionIconAndTitle(action.toLowerCase());
                    return (
                      <Col sm={4} className="mb-2" key={index}>
                        <DropdownItem className="d-flex justify-content-center bg-white">
                          <QuickAction
                            actionIcon={actionIcon}
                            actionTitle={actionTitle}
                            actionColumnSize={4}
                            action={() =>
                              handleQuickActionClick(action.toLowerCase())
                            }
                            showLabel={true}
                            label={actionLabel}
                          />
                        </DropdownItem>
                      </Col>
                    );
                  })}
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              nav
              inNavbar
              className="dropdown-list d-flex align-items-center"
            >
              <DropdownToggle nav className="dropdown-toggle-nocaret p-2 pr-3">
                <img
                  className="rounded-circle"
                  src={_avatar}
                  alt="Avatar"
                  height="30"
                  id="TooltipUser"
                  onClick={toggleTooltip}
                />
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target="TooltipUser"
                  toggle={toggleTooltip}
                >
                  <div className="text-light">{currentAuth0User.name}</div>
                  <div className="text-muted">{currentAuth0User.email}</div>
                </Tooltip>
              </DropdownToggle>
              <DropdownMenu className="animated flipInX p-1" right>
                <DropdownItem className="p-0">
                  {/* START list group */}
                  <ListGroup>
                    <ListGroupItem
                      className="py-0"
                      action
                      tag="a"
                      href="/user/settings"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div>
                        <div className="item user-block pt-2 pb-0">
                          {/* User picture */}
                          <div className="user-block-picture">
                            <div className="user-block-status">
                              <img
                                className="img-thumbnail rounded-circle"
                                src={_avatar}
                                alt="Avatar"
                                width="60"
                                height="60"
                              />
                              <div className="circle bg-success circle-lg"></div>
                            </div>
                          </div>
                          {/* Name and Job */}
                          <div className="user-block-info pt-1 pb-2">
                            <div className="mb-1">
                              Hello, {licenseUser.name}
                            </div>
                            {org && (
                              <small className="pb-2">{org.clientName}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      onClick={() => {
                        logoutChannel.postMessage({
                          logoutMessage: "Oh! You've been logged out.",
                        });
                        handleLogout(actions, logout);
                      }}
                    >
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="text-sm">
                          <i className="fas fa-sign-out-alt mr-1"></i>
                          Logout
                        </span>
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* END Offsidebar menu */}
          </ul>
        )}
        {/* END Right Navbar */}

        {/* START Search form */}
        {!user?.isFirstTimeUser && (
          <form className="navbar-form" role="search" action="search.html">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Type and hit enter ..."
              />
              <div
                className="fa fa-times navbar-form-close"
                data-search-dismiss=""
              ></div>
            </div>
            <button className="d-none" type="submit">
              Submit
            </button>
          </form>
        )}
        {/* END Search form */}

        {showSelectProspectModal && (
          <SelectProspectModal
            showModal={showSelectProspectModal}
            closeModal={() => toggleSelectProspectModal(false)}
            handleAction={(prospectIds, selectedUserId) => {
              setSelectedProspectId(prospectIds);
              setDropdownUserId(selectedUserId);
              toggleSelectProspectModal(false);
              handleOpenQuickActionModal();
            }}
            currentUserId={currentUserId}
            hideUserList={actionType === actionTypes.NOTES}
          />
        )}
        {showEmailsModal && (
          <EmailsModal
            showModal={showEmailsModal}
            hideModal={() => toggleEmailsModal(false)}
            type={'sendOneOff'}
            prospectId={selectedProspectId}
            currentUserId={currentUserId} //logged in user
            currentIndex={1}
            totalCount={1}
            userId={licenseUser.manager ? currentUserId : 0}
            dropdownUserId={dropdownUserId} // selected dropdown user in the relevent parent page
            mailMergeVariables={mailMergeVariables}
            cadenceId={-1}
            showCreateFollowup={true}
          />
        )}
        {showFollowupModal && (
          <LogATaskModal
            showModal={showFollowupModal}
            hideModal={() => setShowFollowupModal(false)}
            prospectId={selectedProspectId}
            currentUserId={currentUserId}
            selectUserId={dropdownUserId}
          />
        )}
        {showAddNoteModal && (
          <AddNote
            showModal={showAddNoteModal}
            setShowModal={setShowAddNoteModal}
            handleSave={(data) => {
              addNotes({
                variables: {
                  input: {
                    note: data.notes,
                    prospect: {
                      id: selectedProspectId,
                    },
                    attachments: data.attachment,
                  },
                },
              });
            }}
            notesLoading={notesLoading}
            handleCancel={() => setShowAddNoteModal(false)}
            attachment={attachment}
            setAttachment={setAttachment}
          />
        )}
        {showAddProspectModal && (
          <AddProspectModal
            currentUserId={currentUserId}
            handleAction={(input) => {
              addProspect({
                variables: {
                  input: [input],
                },
              });
            }}
            hideModal={() => {
              setShowAddProspectModal(false);
            }}
            showModal={showAddProspectModal}
            showActionBtnSpinner={addProspectLoading}
          />
        )}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = function (state) {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
