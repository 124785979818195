import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FIELD_MAPPINGS_FIELDS_QUERY } from '../queries/FieldMappingsQuery';

export const FieldsContext = React.createContext({});

type fieldsList = { fields: { [name: string]: unknown }[] } | null;

export const FieldsProvider = (props: any) => {
  const [contactFields, setContactFields] = useState<fieldsList>(null);
  const [accountFields, setAccountFields] = useState<fieldsList>(null);
  const [opportunityFields, setOpportunityFields] = useState<fieldsList>(null);
  const [totalFields, setTotalFields] = useState<fieldsList>(null);

  const { loading: fieldsLoading, error: fieldsError, refetch } = useQuery(
    FIELD_MAPPINGS_FIELDS_QUERY,
    {
      variables: {
        filter: {
          native: false,
        },
      },
      onCompleted: (response) => {
        setTotalFields(response.fields);
        setContactFields(() => {
        return response?.fields?.filter(
          (field: { [name: string]: unknown }) =>
            field?.recordType === 'Contact'
        ).map((f: { [name: string]: unknown }) => { return { datasource: ['Salesforce'], value: `contact.${f.name}`, displayName: `Contact.${f.label}` , ...f }});
      });
      setAccountFields(() => {
        return response?.fields?.filter(
          (field: { [name: string]: unknown }) =>
            field?.recordType === 'Account'
        ).map((f: { [name: string]: unknown }) => { return { datasource: ['Salesforce'], value: `account.${f.name}`, displayName: `Account.${f.label}` , ...f }});;
      });
      setOpportunityFields(() => {
        return response?.fields?.filter(
          (field: { [name: string]: unknown }) =>
            field?.recordType === 'Opportunity'
        );
      });
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  );

  return (
    <FieldsContext.Provider
      value={{ totalFields, contactFields, accountFields, opportunityFields, fieldsRefetch: refetch, fieldsLoading, fieldsError }}
    >
      {props.children}
    </FieldsContext.Provider>
  );
};
export const FieldsConsumer = FieldsContext.Consumer;

export default FieldsContext;
