import gql from 'graphql-tag';

export const UPDATE_STATIC_ELEMENT = gql`
  mutation UpdateStaticElement(
    $id: ID!
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $weight: Int!
    $text: String!
    $senderId: ID
  ) {
    updatestaticElement(
      id: $id
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        text: $text
        senderId: $senderId
      }
    ) {
      _id
    }
  }
`;

export const UPDATE_TYPETRIGGERED_ELEMENT = gql`
  mutation UpdateTypeTriggeredElement(
    $id: ID!
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $weight: Int!
    $dataPoints: [DynamicVariantsDTOInputType!]!
    $text: String!
    $senderId: ID
  ) {
    updateTypeTriggeredElement(
      id: $id
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        triggerDataPoints: $dataPoints
        text: $text
        senderId: $senderId
      }
    ) {
      _id
    }
  }
`;

export const UPDATE_VALUETRIGGERED_ELEMENT = gql`
  mutation UpdateValueTriggeredElement(
    $id: ID!
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $weight: Int!
    $dataPoints: [DynamicVariantsDTOInputType!]!
    $text: String!
    $senderId: ID
  ) {
    updateValueTriggeredElement(
      id: $id
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        triggerDataPoints: $dataPoints
        text: $text
        senderId: $senderId
      }
    ) {
      _id
    }
  }
`;
