/*
 * @author @Panimaya-Albert
 * @version V11.2
 */
import { useQuery } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import createApolloClient from '../../../apollo/createApolloClient';
import { ApiUrlAndTokenContext } from '../../../auth/ApiUrlAndTokenProvider';
import { showErrorMessage } from '../../../util';
import { FETCH_ORG_LICENSE_QUERY } from '../../queries/LicenseUserQuery';

const useOrgLicense = () => {
  const client = useMemo(() => createApolloClient(), []);
  const [availableProducts, setAvailableProducts] = useState<string[]>([]);
  const { token } = useContext(ApiUrlAndTokenContext);
  const { data, loading, error, refetch } = useQuery(FETCH_ORG_LICENSE_QUERY, {
    client,
    context: {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    },
    onCompleted: (response) => handleOrgLicenseRequestCallback(response, true),
    onError: (response) => handleOrgLicenseRequestCallback(response),
    notifyOnNetworkStatusChange: true,
  });

  const handleOrgLicenseRequestCallback = (
    response: unknown,
    requestSuccess = false
  ) => {
    if (requestSuccess) {
      const licenseData = response?.license?.data?.[0] ?? {};
      const availableProducts = [] as string[];
      Object.keys(licenseData).reduce((acc, license) => {
        if (licenseData[license]?.totalLicenses > 0) {
          acc.push(license);
        }
        return acc;
      }, availableProducts);

      setAvailableProducts(availableProducts);
    } else {
      const technicalDetails = {
        apiEndPoint: 'license',
        apiInitiatedFrom: 'useOrgLicense',
      };
      showErrorMessage(
        response,
        'Failed to fetch org license details',
        data,
        'fetch_org_license_error',
        technicalDetails
      );
    }
  };

  return { data, loading, error, refetch, availableProducts };
};

export default useOrgLicense;
