import React from 'react';
import { KoncertLogodiv } from '@koncert/shared-components';

const Logout = () => {
  const year = new Date().getFullYear();
  return (
    <div className="pb-5">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: '80px' }}
      >
        <h3 className="text-center pl-3">
          <KoncertLogodiv />
        </h3>
      </div>
      <div className="mt-3 d-flex align-items-center justify-content-center">
        <div className="text-center">
          <h3 className="text-success my-4">
            <i className="fa fa-check-circle mr-2"></i>You've successfully
            logged out
          </h3>
          <div>
            <a
              className="h2 text-decoration-none d-inline-block align-middle mb-0 mr-1 font-weight-bold"
              href="./"
            >
              <i className="fas fa-sign-in-alt mr-2"></i>Login
            </a>{' '}
            <span className="h4 d-inline-block align-middle mb-0 mt-1">
              again
            </span>
          </div>
        </div>
      </div>
      <div className="fixed-bottom py-3 text-center border-top">
        &copy; {year} - Koncert
      </div>
    </div>
  );
};

export default Logout;
