import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Footer } from '@koncert/shared-components';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import EmailVerificationAlert from '../Common/EmailVerificationAlert';
import BannerNotifications from '../Common/BannerNotifications';
import SystemSettingsBanner from '../Common/SystemSettingsBanner';
import IdleTimeoutWarningModel from '../../components/Common/IdleTimeoutWarningModel';
import useLookupData from '../Common/hooks/useLookupData.ts';
import Header from './Header';
import Sidebar from './Sidebar';
import { handleLogout } from '../../util/index';
import UserContext from '../UserContext';

const Base = ({ actions, ...props }) => {
  const { logout } = useAuth0();
  const logoutChannel = new BroadcastChannel('logout');
  const { user } = useContext(UserContext);
  const { lookup } = useLookupData();

  const sessionEndTime = lookup?.browser_session_time_out || 600000;

  const [intervalId, setIntervalId] = useState(null);
  const [timeOut, setTimeOut] = useState(sessionEndTime);

  useEffect(() => {
    if (lookup?.browser_session_time_out) {
      setTimeOut(lookup?.browser_session_time_out);
    }
  }, [lookup]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeOut((prevState) => prevState - 1000);
    }, 1000);
    setIntervalId(intervalId);
  }, []);

  const resetIdleTimeOutTimer = () => {
    setTimeOut(sessionEndTime);
  };

  if (timeOut === 0) {
    logoutChannel.postMessage({ logoutMessage: "Oh! You've been logged out." });
    clearInterval(intervalId);
    handleLogout(actions, logout);
  }

  return (
    <div
      className="wrapper"
      onClick={resetIdleTimeOutTimer}
      onScroll={resetIdleTimeOutTimer}
      onMouseMove={resetIdleTimeOutTimer}
      onKeyPress={resetIdleTimeOutTimer}
    >
      {timeOut <= 60000 && (
        <IdleTimeoutWarningModel
          timeOut={timeOut}
          resetIdleTimeOutTimer={resetIdleTimeOutTimer}
        />
      )}
      {window.location.pathname.indexOf('settings/email/callback') === -1 &&
        !user?.isFirstTimeUser && <Header />}
      {!user?.isFirstTimeUser && <Sidebar sidebarAnyclickClose={false} />}
      <section
        className={`section-container ${user?.isFirstTimeUser ? 'onboard-section-container' : ''
          }`}
        style={
          user?.isFirstTimeUser
            ? { marginLeft: '0', marginTop: '0', marginBottom: '0' }
            : {}
        }
      >
        {window.location.pathname.indexOf('settings/email/callback') === -1 && !user?.isFirstTimeUser && <SystemSettingsBanner />}

        {window.location.pathname.indexOf('settings/email/callback') === -1 &&
          !user?.isFirstTimeUser && <EmailVerificationAlert />}
        {window.location.pathname.indexOf('settings/email/callback') === -1 && !user?.isFirstTimeUser && <BannerNotifications />}

        {props.children}
      </section>
      {window.location.pathname.indexOf('settings/email/callback') === -1 &&
        !user?.isFirstTimeUser && (
          <Footer productName={props.productName} user={user} />
        )}
    </div>
  );
};

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Base);
