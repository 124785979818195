import gql from 'graphql-tag';

export const ACCOUNTCOVERAGEFUNNEL = gql`
  query accountCoverageFunnel(
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
    $sendersId: [ID!]!
  ) {
    accountCoverageFunnel(
      format: $format
      startDate: $startDate
      endDate: $endDate
      sendersId: $sendersId
    ) {
      id
      startDate
      endDate
      totalAccountData
      totalMonitored
      totalNotDnc
      totalContacted
      totalEngaged
      totalOpps
      totalOppsWon
    }
  }
`;

export default ACCOUNTCOVERAGEFUNNEL;
