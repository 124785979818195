import React, { useState } from 'react';
import {
  Button,
  ModalFooter,
  Form,
  FormGroup,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
  Input,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  STORYQUERYSTRING,
  CREATE_STATIC_ELEMENT,
  UPDATE_STATIC_ELEMENT,
  STORIESCONTACTSTATISTICSQUERY,
} from '@koncert/graphql';

import SpinnerButton from '../Extras/SpinnerButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { text } from '../Forms/FormValidatorPattern';
import { useSendersList } from '@koncert/shared-components';

const StaticElement = ({
  element: elementArray,
  plotPoint,
  isDefault,
  toggleModal,
  story,
  user,
  userLoading,
  onModified,
  clone = false,
  setplotPointAction,
  onboardingRefetch,
}) => {
  const element = Array.isArray(elementArray) ? elementArray[0] : elementArray;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { text: element?.text },
  });
  let plotPointAsDefaultId = isDefault ? plotPoint?._id : null;
  let plotPointAsAdditionalId = !isDefault ? plotPoint?._id : null;
  const [weight] = useState(element?.weight || 0);

  const [createStaticElement, { loading: createLoading }] = useMutation(
    CREATE_STATIC_ELEMENT,
    {
      onCompleted: () => {
        onboardingRefetch();
        setplotPointAction('updated');
      },
    }
  );
  const [updateStaticElement, { loading: updateLoading }] = useMutation(
    UPDATE_STATIC_ELEMENT,
    {
      onCompleted: () => setplotPointAction('updated'),
    }
  );

  const defaultSenderId = userLoading
    ? null
    : user.rolesMask < 4
    ? element?.senderId
    : user.id;
  const disableSelectingOtherSenders = element?._id || user?.rolesMask > 2;
  const { SendersDropdown, senderId } = useSendersList(
    user,
    userLoading,
    defaultSenderId,
    false,
    disableSelectingOtherSenders,
    false,
    user?.rolesMask > 2
  );

  const onSubmit = (data) => {
    const { text } = data;

    if (element?._id && element?.senderId === senderId) {
      updateStaticElement({
        variables: {
          id: element._id,
          text: text,
          weight: weight,
          senderId: senderId,
          plotPointCategoryId: plotPoint.plotPointCategoryId,
        },
        refetchQueries: [
          {
            query: STORYQUERYSTRING,
            variables: { storyId: story._id },
            awaitRefetchQueries: true,
          },
          {
            query: STORIESCONTACTSTATISTICSQUERY,
            variables: { storyId: story._id },
          },
        ],
      }).then((result) => {
        toggleModal();
        onModified();
      });
      return;
    }

    if (!!plotPointAsDefaultId && senderId !== element?.senderId) {
      // ensure it's created as an additional if this is for a different sender
      plotPointAsAdditionalId = plotPointAsDefaultId;
      plotPointAsDefaultId = null;
    }

    createStaticElement({
      variables: {
        text: text,
        weight: weight,
        senderId: senderId,
        plotPointAsDefaultId: plotPointAsDefaultId,
        plotPointAsAdditionalId: plotPointAsAdditionalId,
        plotPointCategoryId: plotPoint.plotPointCategoryId,
      },
      refetchQueries: [
        {
          query: STORYQUERYSTRING,
          variables: { storyId: story._id },
          awaitRefetchQueries: true,
        },
        {
          query: STORIESCONTACTSTATISTICSQUERY,
          variables: { storyId: story._id },
        },
      ],
    }).then((result) => {
      toggleModal();
      onModified();
    });
  };
  const { ref: textRef, ...textRest } = register('text', text);
  return (
    <Form name="formStaticElement" onSubmit={handleSubmit(onSubmit)}>
      <Card className={'border-info'}>
        <CardHeader className="bg-info">
          <CardTitle>Static Text Trigger</CardTitle>
        </CardHeader>
        <CardBody className="">
          Static text elements are not allowed to contain any variables
        </CardBody>
      </Card>
      <FormGroup>
        <label className="mr-2">Select Sender (optional)</label>
        <SendersDropdown />
      </FormGroup>
      <FormGroup>
        <label>Text</label>
        <Input
          {...textRest}
          className="form-control"
          type="textarea"
          name="text"
          invalid={errors.text}
          rows={10}
          placeholder="Text"
          innerRef={textRef}
        />
        <ErrorMessage
          errors={errors}
          className="invalid-feedback"
          name="text"
          as="p"
        ></ErrorMessage>
      </FormGroup>

      <ModalFooter className="pt-2 pb-0 px-0">
        {senderId !== element?.senderId && senderId === user?._id && (
          <span className="text-warning">
            This will create a new variant just for your messages
          </span>
        )}

        {element?.senderId && senderId === null && user?.rolesMask < 4 && (
          <span className="text-warning">
            This will create a new variant for all senders
          </span>
        )}

        <Button color="secondary" onClick={toggleModal}>
          <i className="fa fa-times mr-2"></i>
          Cancel
        </Button>
        {(!element?._id ||
          (element?._id &&
            senderId !== element?.senderId &&
            senderId === user?._id) ||
          (element?._id &&
            senderId == null &&
            element?.senderId &&
            user.rolesMask < 4)) && (
          <SpinnerButton
            type="submit"
            color="primary text-white"
            loading={createLoading || updateLoading}
          >
            <i className="fa fa-check mr-2"></i>
            Create
          </SpinnerButton>
        )}

        {element?._id &&
          ((user?.rolesMask < 4 && element?.senderId === senderId) ||
            user?._id === element?.senderId) && (
            <SpinnerButton
              type="submit"
              color="primary text-white"
              loading={createLoading || updateLoading}
            >
              <i className="fa fa-check mr-2"></i>
              Save
            </SpinnerButton>
          )}
      </ModalFooter>
    </Form>
  );
};

export default StaticElement;
