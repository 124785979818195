import gql from 'graphql-tag';

export const USER_FETCHABLES_QUERY = gql`
  query UserFetchablesQuery($userId: ID!) {
    fetchables(
      where: { userId: $userId, responseStatus: null }
      limit: 10
      order: { priority: DESC }
    ) {
      _id
      type
      createdAt
      url
      responseStatus
      workerStatus
      responseBodySize
      fetchedAt
      processedAt
    }
    fetchablesMeta(where: { userId: $userId, responseStatus: null }) {
      count
    }
  }
`;

export const SENDER_FETCHABLES_COUNT = gql`
  query SenderFetchablesCount($userId: ID!) {
    fetchablesMeta(where: { userId: $userId, responseStatus: null }) {
      count
    }
  }
`;

export const SENDER_LINKEDIN_FETCHED_COUNT = gql`
  query senderLinkedInFetchedCount($userId: ID!) {
    liFetchQuota(senderId: $userId)
  }
`;
