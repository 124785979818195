import gql from 'graphql-tag';

export const PLOTPOINT_CATEGORIES_QUERY = gql`
  query getPlotPointCategories {
  getPlotPointCategories{
    _id
    name
  }
}
`;
