import React, { useContext, useEffect } from 'react';
import {
  REFRESH_STORY_CONTACT,
  USER_STORY_CONTACT_MESSAGES_QUERY,
} from '@koncert/graphql';
import EmailMessages from './EmailMessages';
import { useLazyQuery, useMutation } from '@apollo/client';
import ProgressLoader from '../Common/ProgressLoader'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FieldsContext from 'apps/stories/src/components/Common/FieldsContext';

export const ExpandedRow = ({ currentUser, userLoading, row, selectedId, selectedSection }) => {
  const storyCont = row?.original?.storyContacts?.find(
    (story) => story._id === selectedId
  );
  const selectedStory =
    selectedId && storyCont?._id
      ? storyCont
      : row?.original?.storyContacts[row?.original?.selectedStoryIndex];
  const [
    refreshStoryContactMutation,
    { loading: refreshLoading },
  ] = useMutation(REFRESH_STORY_CONTACT);

  const refreshStoryContact = () => {
    return refreshStoryContactMutation({
      variables: { id: selectedStory._id },
      refetchQueries: [
        {
          query: USER_STORY_CONTACT_MESSAGES_QUERY,
          variables: { id: selectedStory._id },
        },
      ],
    });
  };

  const [myQueryExecutor, { data, loading, error }] = useLazyQuery(
    USER_STORY_CONTACT_MESSAGES_QUERY,
    {
      variables: { id: selectedStory._id },
      skip: refreshLoading,
    }
  );
  const datasourcesList = {
    Salesforce: ' SF',
    LinkedIn: ' LI',
  };
  const {
    contactFields: contactFieldsList,
    accountFields: accountFieldsList,
    fieldsLoading: fieldsListLoading,
  } = useContext(FieldsContext);

  useEffect(() => {
    myQueryExecutor();
  }, [myQueryExecutor]);

  if (loading || refreshLoading || fieldsListLoading)
    return <ProgressLoader pageName="Message" />;

  if (error) return <i className="fa fa-exclamation-triangle text-danger"></i>;

  const storyContact = data?.storyContact || {};

  const handleshowDataSource = (dataSources) => {
    const formattedDataSources = dataSources?.map(
      (datasource) => datasourcesList[datasource]
    );
    const dataSourcesInformation = formattedDataSources
      ?.filter(Boolean)
      ?.toString()
      .trim();
    return dataSourcesInformation ? dataSourcesInformation : '-';
  };
  
  const dataPointTypes = !fieldsListLoading && contactFieldsList?.length > 0 && accountFieldsList?.length > 0
    ? [...contactFieldsList, ...accountFieldsList]
    : [];

  const pascalToNatural = (str) => {
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return str;
  };

  const options = dataPointTypes?.map((dp) => {
    const dataSources = handleshowDataSource(dp.datasource);
    return {
      value: dp.value,
      label: `${pascalToNatural(dp.displayName)} ${
        dataSources !== '-' ? `(${handleshowDataSource(dp.datasource)})` : ''
      }`,
      displayName: pascalToNatural(dp.displayName)
    };
  });

  const optionsValueGrp = options.reduce((acc, curr) => {
    acc[curr.value] = curr.displayName;
    return acc;
  },{});

  const optionsLabelGrp = options.reduce((acc, curr) => {
    acc[curr.displayName] = curr.value;
    return acc;
  },{});


  return (
    <>
      {/* {refreshLoading && <i className="fa fa-spin fa-spinner"></i>}
      {!refreshLoading && (
        <Button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            refreshStoryContact(row.original.id);
            return false;
          }}
        >
          <i className="fa fa-refresh"></i> Refresh Contact
        </Button>
      )} */}
      {storyContact && (
        // <pre>{JSON.stringify(storyContact)}</pre>
        <EmailMessages
          currentUser={currentUser}
          userLoading={userLoading}
          row={row}
          selectedStory={selectedStory}
          emailContent={storyContact?.emailContent}
          storyId={selectedStory?.story?._id}
          elements={storyContact?.elements}
          options={options}
          optionsValueGrp={optionsValueGrp}
          optionsLabelGrp={optionsLabelGrp}
          elementToMessageLookup={storyContact?.elementToMessageLookup}
          refreshMessages={refreshStoryContact}
          selectedSection={selectedSection}
        />
      )}
    </>
  );
};
