/**
 * @author @rkrishna-gembrill
 * @since Jun 22 2020
 * @version V11.0
 */
import React from 'react';
import { Button } from 'reactstrap';

// TODO: when converting to typescript, please extend the props type with html button and reactstrap button
const ClButton = (props) => {
  const newProps = { ...props };
  delete newProps.icon;

  return (
    <Button {...newProps}>
      {props.icon && <i className={`${props.icon} mr-2`}></i>}
      {props.children}
    </Button>
  );
};

export default ClButton;
